import en2 from "./en2.js";
export default {
  ...en2,
  home: "Home",
  products: "Products",
  securities: "Securities",
  futures: "Futures",
  cfds: "CFDs",
  tools: "Tools",
  tradingPlatform: "Trading platform",
  tradingTools: "Trading Tools",
  vps: "VPS",
  trading: "Trading",
  pricingOverview: "Pricing Overview",
  commission: "Commission",
  generalFee: "General Fee",
  marginRate: "Margin Rate",
  tradingService: "Trading Service",
  funding: "Funding",
  partnership: "Partnership",
  exchangePartners: "Exchange Partners",
  introducingBroker: "Introducing Broker",
  whiteLabelPartner: "White Label Partner",
  aboutUs: "About Us",
  ourStory: "Our Story",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms Of Service",
  contactUs: "Contact Us",
  helpCenter: "Help Center",
  dooEntitiesNotice: "LayRay Entities Notice",
  RiskDisclosure: "Risk Disclosure",
  RiskDisclosureContent1:
    "Online trading of securities, futures, currencies, foreign equities, CFDs and other financial instruments involves substantial risk of loss due to unpredictable market movements, and can result in substantial losses exceeding your initial investment. Past performance of an investment is not an indicator of its performance in the future. You should consider the risks involved in trading any financial instrument to ensure that you fully understand the same and may do so by seeking independent financial advice before you decide to trade. Any trading symbols displayed herein are for illustrative purposes only and shall not constitute any advice or recommendation by us. LayRay shall bear no liability to you for any direct, indirect, special, consequential or incidental loss and/or damage arising from your transactions.",
  BrandandEntityNotice: "Brand and Entity Notice",
  BrandandEntityNoticeContent1:
    "Layray's brand name and intellectual property rights.LayRay reserves all rights to LayRay and its related brands, including but not limited to LayRay Tech, LayRay Prime, LayRay Consulting, LayRay Clearing, Peter Elish, etc.",
  BrandandEntityNoticeContent2:
    "We have a variety of regulated and licensed entities in different jurisdictions to provide a variety of financial products and services. You can engage in business transactions with any of our entities, subject to applicable laws and regulations. Please bear in mind that the entity chosen shall mean that your trading account, trading activities and funds will be governed and regulated by the respective regulatory authority of the jurisdiction whereupon the entity is located.",
  BrandandEntityNoticeContent3: "We either:",
  BrandandEntityNoticeContent4:
    " (i) offer limited access through the applicable and relevant entity; or",
  BrandandEntityNoticeContent5: "(ii) do not offer our services;",
  BrandandEntityNoticeContent6:
    "to individuals or companies of certain jurisdictions, including but not limited to Afghanistan, Azerbaijan, Bosnia and Herzegovina, Burma, Canada, Congo, Cuba, Ethiopia, Guyana, Haiti, Hong Kong, Indonesia, Iran, Iraq, Israel, Japan, Lebanon, Malaysia, Mali, North Korea, Singapore, Sri Lanka, Somalia, Sudan, Trinidad and Tobago, Tunisia, Venezuela, United Arab Emirates and the United States of America. The information and services on our website are not applicable and shall not be provided to a country or jurisdiction where such distribution of information and services is contrary to the respective local statutes and regulations. Visitors from the above regions should confirm whether your decision to invest in our services is in accordance with the statutes and regulations of your country or jurisdiction before you use our services. We reserve our rights to vary, amend or discontinue our products and services at any time.",
  WebsiteTerms: "Website Terms and Conditions",
  WebsiteTermsContent1:
    "This website is owned and operated by LayRay Singapore Pte. Ltd. (Company Registration Number 202104581D). By accessing and/or using this website, you agree to be bound by any terms and conditions, statements, disclaimers and notices contained in this website. You must be at least 18 years old to access and/or use our website, products and services. By accessing and/or using this website, you confirm that you are an individual of full age and capacity in compliance with the local laws and regulations of your country. ",
  WebsiteTermsContent2:
    "The nature of the information provided on this website is general wherein your or your client's objectives, financial needs or circumstances have not been taken into account.",
  WebsiteTermsContent3:
    "The information provided on this website together with the products and services offered on this website are not intended to be provided to any person or entity in any country or jurisdiction where such provision is contrary to local laws and/or regulations. ",
  WebsiteTermsContent4:
    "Any comment, statement or data provided, material or third party material (“Material”) provided on this website is solely for illustrative purpose only. The Material shall neither be specific to any person or organisation with publicity, promotion, and/or marketing activities, and does not contain, and should not be construed as business advice, tax advice, trading advice, legal or regulatory advice, investment advice, investment recommendation, any form of commitment, and/or offer for any transactions. While we have taken all reasonable efforts to ensure the accuracy and completeness of the information provided, we make no representations nor warranties to the Material and shall not be liable for any loss, including but not limited to loss of profit, direct or indirect loss or damages for any inaccuracies and/or incompleteness from the information provided. You shall only use the Material for personal use and shall not reproduce, copy, redistribute and/or license the Material without our consent. ",
  WebsiteTermsContent5:
    "We may modify this website from time to time without prior notice to you and your continued access and/or use of this website shall represent your acceptance of the modifications. ",
  WebsiteTermsContent6:
    "We comply with all applicable laws and regulations regarding the use of your personal information, for more information, please see our Privacy Policy. ",
  WebsiteTermsContent7:
    "We use cookies on our websites to customize the information and experience displayed on our website according to your preferences. By accessing this website, you acknowledge that you have read and agree to the details above and agree to our use of cookies. For more information on our use of cookies, please see our Cookie Policy. ",
  WebsiteTermsContent8:
    "We strictly comply with all applicable laws and regulations of the relevant jurisdictions where our entities operate. It is your responsibility to determine and ensure that your investment meets the requirements of your local jurisdiction. You undertake to bear all the consequences of your investment and trading activities. ",
  WebsiteTermsContent9:
    "If you have any questions or comments, or if you have a concern about the way in which we provide services in certain jurisdictions. You may also contact us by email.",
  asset:
    "* Asset insurance is only applicable to customer accounts at Peter Elish Investments Securities.",
  company:
    "This website is owned and operated by LayRay Holding Pte. Ltd. (Company No. 201930035D)",
  OnestopInvestmentChannel: "One-stop Investment Channel",
  OnestopInvestmentChannelContent:
    "Access to global financial markets<br />Trade multiple assets with one single account",
  TradeontheGlobalExchanges: "Trade on the Global Exchanges",
  TradeontheGlobalExchangesCotent:
    " Encompassing worldwide high-quality assets <br />Discover and choose in demand financial products from more than 10,000 varieties<br />",
  homeBanner3: "Competitive Pricing in the Industry",
  homeBanner3Content:
    "Commission from HK$3 for HK stock trading，US$0.003/share for US stock trading. US$0.085/lot for futures trading， Spread from 0.1 pips for CFDs trading",
  homeBanner4: "Cutting-edge Technology Support",
  homeBanner4Content:
    " 99.5% of orders executed within 50 milliseconds <br />Ultra-low latency, stable and reliable <br />",
  homeBanner5: "Display on Nasdaq Tower",
  homeBanner5Content:
    "Establishing a fully disclosed brokerage relationship with Interactive Brokers",
  ReKnowMore: "ReKnow More",
  GlobalInvestmentsViaOneAccount: "Global Investments Via One Account",
  GlobalInvestmentsViaOneAccountContent:
    "With our robust technological advancement, Lay Ray has managed to launch itself into the global financial market, providing a diverse range of trading opportunities, including Securities, Futures and Contract for Differences (CFDs), allowing clients to invest globally through a single online trading account.",
  InitialCommissionFee: "Initial Commission Fee",
  USStockTrading: "US Stock Trading",
  ETFslistedonUSExchanges: "ETFs listed on US Exchanges",
  MutualFundsInvestment: "Mutual Funds Investment",
  AllFee: "Commission Fee, Custody Fee and Booking Fee",
  StockOptions: "Stock Options",
  CommissionFee: "Commission Fee",
  alot: "a lot",
  Forex: "Forex",
  Spreadfrom: "Spread from",
  PreciousMetals: "Precious Metals",
  Energy: "Energy",
  IndexCFDs: "Index CFDs",
  StockCFDs: "Stock CFDs",
  FuturesCFDs: "Futures CFDs",
  Licensed: "Licensed and Regulated by Financial Regulatory Authorities",
  LicensedContent: "Licensed and Regulated by Financial Regulatory Authorities",
  Experience: "Experience Cutting-edge Technology",
  ExperienceContent:
    "As a online brokerage brand providing fintech services, Lay Ray has always committed to technology R&amp;D and innovation, constantly amplifying and elevating its functions and capability, to offer our clients the ultimate trading experience.",
  UserCenter:
    "User Center<span>An easy-to-use customized user interface on multiple devices. Register on website and mobile application now and experience the powerful trading system at anytime and anywhere.</span>",
  DataSecurity:
    "Data Security<span>Site-wide SSL 256-bit encryption plus database internal and external network isolation, ensure your personal information and trading data is securely encrypted in transmisstion.</span>",
  HardwareSupport:
    "Hardware Support<span>Global network connected with trading cCentrals located at Equinix® data centers in global financial hubs, including London (LD5), New York (NY5), Hong Kong (HK2), Singapore (SG1) .</span>",
  MultiplePlatforms: "Multiple Platforms",
  MultiplePlatformsContent:
    "Lay Ray provides a variety of internationally recognized and reliable trading platforms with advance mechanisms and functions to assist you in recognizing and seizing trading opportunities, ultimately elevating your trading experience.",
  InTradeContent:
    "Using existing and emerging technologies, Lay Ray has developed a professional cloud trading system which allows global investors and brokers to manage multiple accounts effortlessly.",
  KnowMore: "Know More",
  TradingViewContent:
    "The powerful cloud trading platform which provides real-time charts, in-depth analysis and trading ideas of the world's top investors.",
  MetaTrader4Content:
    "The world's most popular, user-friendly and highly versatile trading platform which supports EA automated trading and multi-account management system.",
  MetaTrader5Content:
    "The next generation multi-asset trading platform, with professional technical analysis tools, high flexibility and reliability, and a better performance than MT4.",
  SecurityofFunds: "Security of Funds",
  SecurityofFundsContent:
    "As a financial entity, ensuring the security of clients' assets is one of our core responsibilities and our unchanging values.",
  ExtensivelyRegulated: "Extensively Regulated",
  ExtensivelyRegulatedContent:
    "Our customers' assets and transactions are strictly supervised by the corresponding financial regulatory agencies of LayRay in the financial entities in various countries/regions around the world.FCA), Mauritius Financial Services (MFSC) and Vanuatu Financial Services (VFSC).Applicable relevant laws and regulations will depend on the financial entity of customers' account opening.",
  AssetsInsurance: "Assets Insurance",
  AssetsInsuranceContent:
    "Our customers' assets and transactions are strictly supervised by the corresponding financial regulatory agencies of LayRay in the financial entities in various countries/regions around the world.FCA), Mauritius Financial Services (MFSC) and Vanuatu Financial Services (VFSC).Applicable relevant laws and regulations will depend on the financial entity of customers' account opening.",
  FundsSegregation: "Funds Segregation",
  FundsSegregationContent:
    "Client's assets and the operating funds of our financial entities are independently segregated, and are maintained in Standard Chartered Bank, Bison Bank and Barclays Bank where the accounts are maintained strictly in compliance with the financial regulations of the international banks, effectively securing our clients funds in their designated accounts.",
  GlobalPresence: "Global Presence",
  GlobalPresenceContent:
    "Lay Ray is the Internet brokerage brand. LayRay has established a global operation office in Dallas, London, Singapore, Hong Kong, Dubai, and Kuala Lumpur. Through the globalized layout, it provides one -stop global investment and financial trading services for high net worth customers.",
  IntroducingBrokers: "Introducing Brokers",
  InstitutionalPartners: "Institutional Partners",
  Employeesin11cities: "Employees in 11 cities",
  securitiesContent:
    "Trade 10,000+ U.S. and Hong Kong stock products via one Lay Ray account<br /> and take yields on the dividends of the world&#039;s top listed companies.",
  WhatAreSecurities: "What Are Securities?",
  Securitiesopportunities:
    "Securities - or also known as stock trading - is one of the most popular forms of investment. Lay Ray offers powerful stock trading tools and competitive stock trading commissions to help you access multiple markets and take advantage of global opportunities.",
  USStocks: "U.S. Stocks",
  USStocksContent1:
    "The U.S. stock market is the most developed stock market in the world, and is second to none in the world in terms of the number of stocks issued and traded, as well as in terms of stock market capacity and market development.",
  USStocksContent2: "Benefits of Investing in U.S. Stocks",
  USStocksContent3:
    "The world's largest market capitalization and most liquid stock market",
  USStocksContent4: "TGlobal top companies and high rated stocks",
  USStocksContent5:
    "Reasonable valuation and focuses more on shareholder return",
  USStocksContent6: "Diversified trading instruments",
  USStocksContent7:
    "Lay Ray is a rising online brokerage offering a wide range of U.S. stocks, competitive U.S. stock commissions and a full range of online services. All stocks traded on the NYSE and NASDAQ can be traded through Lay Ray.",
  HongKongStocks: "Hong Kong Stocks",
  HongKongStocksCotent1:
    "Hong Kong stocks are stocks listed on the Hong Kong Stock Exchange. As the largest international financial center in Asia, Hong Kong is the leading market for listing and financing in Asia.",
  HongKongStocksCotent2: "Benefits of Investing in Hong Kong Stocks",
  HongKongStocksCotent3: "Strict regulation, stronger security on capital",
  HongKongStocksCotent4:
    "High rated companies with high appreciation potential",
  HongKongStocksCotent5:
    "A and H share price difference has room for arbitrage",
  HongKongStocksCotent6:
    "New shares winning rate is generally higher than A shares",
  HongKongStocksCotent7:
    "Compared to the Mainland, the Hong Kong market is more mature, rational and responsive to the world market, and Lay Ray, as a rising online broker, offers a wide range of Hong Kong stock trading products and diversified investment portfolios to provide investors more choices.",
  SecureExperience: "Secure and Seamless Trading Experience",
  DiverseInvestmentCategories: "Diverse Investment Categories",
  DiverseInvestmentCategoriesContent:
    "With one account, you can invest in a wide range of stocks, ETFs, mutual funds, stock options and more.",
  CompetitiveTradingCosts: "Competitive Trading Costs",
  CompetitiveTradingCostsContent:
    "We offer the most competitive trading costs in the industry to help investors achieve greater profit margins.",
  TradinginAnytime: "Trading in Anytime",
  Tradefingertips:
    "Trade on your mobile anytime, anywhere and enjoy global investments at your fingertips.",
  EfficientTrading: "Efficient Trading",
  EfficientTradingContent:
    " Our data centers are deployed at Equinix, where 99.5% of trade orders are executed in 50 milliseconds.",
  InternationalFinancialRegulation: "International Financial Regulation",
  TheoperatingKingdom:
    " The operating entities in each region are subject to strict regulation by international regulatory bodies such as SEC, FINRA and FCA in the United Kingdom.",
  ProfessionalSupport: "24/7/365 Professional Support",
  With500:
    " With 500+ industry's professionals, LayRay provides top-notch support services to investors 24/7, 365 days a year.",
  DirectAccess: "Direct Access to Global Stock Exchanges",
  OneAccount:
    "One account that encompasses all the global stock exchange. Trade the global financial markets, seamlessly and efficiently trading.One account that encompasses all the global stock exchange. Trade the global financial markets, seamlessly and efficiently trading.",
  NewYorkStockExchange: "New York Stock Exchange",
  NasdaqStockExchange: "Nasdaq Stock Exchange",
  HongKongStockExchange: "Hong Kong Stock Exchange",
  MultiplePlatforms: " Multiple Platforms ",
  tradingsoftwaredes:
    " Multiple popular software at your disposal to elevate your professional and convenient trading experience ",
  InTrade: " The next generation of mobile-centric trading platform ",
  TradingView: " HTML 5 Trading Platform ",
  MetaTrader4: " The world's most popular trading platform ",
  MetaTrader5: " The new generation of multi-asset trading platform ",
  productsharesheightitle: " For More Information About Securities ",
  USStockTradingHours: "U.S. Stock Trading Hours",
  HongKongStockTradingHours: "Hong Kong Stock Trading Hours",
  USEasternTime: "U.S. Eastern Time (EST)",
  BeijingTime1: "Beijing Time (Daylight Saving Time)",
  BeijingTime2: "Beijing Time (Winter Time)",
  Pretrade: "Pre-trade",
  Intraday: "Intraday",
  Afterhours: "After hours",
  CFDsText:
    "Enjoy extremely low spreads and commissions as low as 0.1 pips on 10,000+ CFD products, including stocks, indices, futures and more, with the flexibility to go long or short with the opportunity to make a profit.",
  CFDs: "CFDs",
  FuturesText:
    " We offer a wide range of futures from the main exchanges,including the NASDAQ, S&amp;P 500 and other global stock indices, as well as gold, silver, crude oil and other commodities.",
  Futures: "Futures",
  SecuritiesText:
    " Trade more than 10,000+ U.S. and Hong Kong stocks on the world's leading exchanges, yield profits on the dividends of top listed companies and enjoy competitive stock commissions.",
  Securities: "Securities",
  ExploreAllDooFinancialProducts: "Explore All Lay Ray Products",
  faqText4:
    " Unlike A-shares, Hong Kong stocks do not have single-day limit, so investors need to pay attention to control the risk.",
  faqTitle4: "Do Hong Kong stocks have price rise and fall limits?",
  faqTitle3: "  What is the minimum trading unit for Hong Kong stocks?",
  faqText3:
    '  The minimum trading unit for Hong Kong stocks is "1 lot" and the number of shares per lot is determined by the price of the stock. 1 lot may be 100, 500, 1,000 and 2,000 shares. For example, each lot of Tencent Holdings is 100 shares and each lot of BYD is 500 shares. A position of less than one lot is considered a "fractional share". For example, Tencent Holdings, a lot of 100 shares, if you hold 150 shares of Tencent, then 50 shares will become fractional shares. Usually when the company sends bonus shares or rights issue will produce fractions of shares.',
  faqTitle2: "Do U.S. stocks have price rise and fall limits?",
  faqText2:
    " Unlike A-shares, U.S. stocks do not have single-day limit, so investors need to pay attention to control the risk.",
  faqText1:
    ' Unlike A-shares and Hong Kong stocks, U.S. stocks do not have the concept of "lot" and the minimum trading unit is one share.',
  faqTitle1: "What's the minimum trading unit for U.S. stocks?",
  FAQ: "FAQ",
  ClosingSessionLabel:
    "The closing time of the regular trading day of the securities market has been changed from 4:00 p.m. to 4:08 p.m. - 4:10 p.m. The closing time of the half-day market has been changed from 12:00 noon to 12:08 p.m. - 12:10 p.m.",
  ClosingSession: "[Closing Session]",
  Afternoon: "Afternoon",
  Morning: "Morning",
  ContinuousTradingSession:
    "[Continuous Trading Session] i.e. Normal Trading Session",
  AM: "AM",
  MorningPoolingSession: "[Morning Pooling Session]",
  TradingTakes:
    "Trading takes place Monday through Friday, with the following trading hours.",
  WhatAreFuturesText:
    "As the most important derivative instrument for managing price risk,futures are standardized contracts in which buyers and sellers agree to buy or sell a specified instrument at a specified date and time, at a pre-confirmed price and other trading conditions.",
  WhatAreFutures: "What Are Futures?",
  FuturesBannerText:
    " Dive into the world;s eading futures trading markets, covering stock indices, commodities, energy, stocks, interest rates, and agricultural products, and enjoy trading global futures.",
  BenefitsofInvestingonFutures: "Benefits of Investing on Futures",
  BenefitsofInvestingonFuturesText1:
    "Two-way trading, both bullish and bearish",
  BenefitsofInvestingonFuturesText2:
    "Margin trading, employ full leverage, increase the efficiency of capital utilization",
  BenefitsofInvestingonFuturesText3: "Flexible trading with T+0 trading system",
  BenefitsofInvestingonFuturesText4:
    "Hedging with stocks and other trading products to reduce risk",
  BenefitsofInvestingonFuturesText5:
    "Lay Ray provides investors with access to global futures products, including equity indices, commodities, energy, securities, interest rates, and agricultural futures, connecting them to the world's leading futures markets. Trade a wide range of futures directly from the CME Group (Chicago Mercantile Exchange), CBOE Chicago Board Options Exchange, HKFE Hong Kong Futures Exchange, and SGX Singapore Exchange with us at Lay Ray!",
  SecureandSeamlessTradingExperience: "Secure and Seamless Trading Experience",
  SecureandSeamlessTradingExperienceTitle1: "Trade from All Gobal Futures",
  SecureandSeamlessTradingExperienceText1:
    "Trade up to 200+ global futures products from the world's leading exchanges.",
  SecureandSeamlessTradingExperienceTitle2: "Competitive Trading Costs",
  SecureandSeamlessTradingExperienceText2:
    "We offer the most competitive trading costs in the industry to help investors achieve greater profit margins.",
  SecureandSeamlessTradingExperienceTitle3: "Trading in Anytime",
  SecureandSeamlessTradingExperienceText3:
    "Trade on your mobile anytime, anywhere and enjoy global investments at your fingertips.",
  SecureandSeamlessTradingExperienceTitle4: "Efficient Trading",
  SecureandSeamlessTradingExperienceText4:
    "Our data centers are deployed at Equinix, where 99.5% of trade orders are executed in 50 milliseconds.",
  SecureandSeamlessTradingExperienceTitle5:
    "International Financial Regulation",
  SecureandSeamlessTradingExperienceText5:
    "he operating entities in each region are subject to strict regulation by international regulatory bodies such as SEC, FINRA and FCA in the United Kingdom.",
  SecureandSeamlessTradingExperienceTitle6: "24/7/365 Professional Support",
  SecureandSeamlessTradingExperienceText6:
    "With 500+ industry's professionals, LayRay provides top-notch support services to investors 24/7, 365 days a year.",
  DirectAccesstoGlobalFuturesExchanges:
    "Direct Access to Global Futures Exchanges",
  DirectAccesstoGlobalFuturesExchangesText:
    "Trade the global futures, seamlessly and efficiently via one account that encompasses all markets.",
  NewYorkCommodityExchange: "New York Commodity Exchange",
  NewYorkMercantileExchange: "New York Mercantile Exchange",
  SingaporeExchange: "Singapore Exchange",
  ChicagoBoardofTrade: "Chicago Board of Trade",
  CMEGroup: "CME Group",
  EuropeanFuturesExchange: "European Futures Exchange",
  HongKongFuturesExchange: "Hong Kong Futures Exchange",
  MultiplePlatforms: "Multiple Platforms",
  ForMoreInformationAboutFutures: "For More Information About Futures",
  ForMoreInfoTitle1: "Futures Trading Hours",
  ForMoreInfoText1:
    "According to the rules of the exchange, each futures product has different trading hours. Please kindly check in the Lay Ray trading software for more details, subject to the announcement of the exchange.",
  ForMoreInfoTitle2: "Minimum trading unit for futures",
  ForMoreInfoText2:
    "he minimum unit of trading in futures is a standard contract, usually called a lot. The specifications of standard contracts vary from one futures product to another.",
  ForMoreInfoTitle3: "Futures price changes",
  ForMoreInfoText3:
    "Futures varieties have a wide range of limits, and some contracts do not have limits. However, investors shall understand and pay attention in controlling the risks.",
  ForMoreInfoTitle4: "Types of futures trading",
  ForMoreInfoText4:
    "Trading in futures contracts is conducted on a margin basis, with the investors requiring only a certain percentage of the contract value (margin ratio below 50%, usually around 10%) for the initial margin to trade.",
  cfdsTitle:
    "Trade 10,000+ CFD products around the world with highly competitive spreads and efficient execution.",
  WhatAreCFDs: "What Are CFDs?",
  WhatAreCFDsTitle:
    "Contract for Difference (CFD) is a type of contract trading that does not involve any exchange of physical commodities or securities, but only the difference between the settlement price and the contract price for cash settlement of a trading method, instead, the professional team of brokers to hedge, risk control, so that users can trade conveniently, without having to consider too many regulatory rules, such as Short Sell.",
  BenefitsofInvestinginCFDs: "Benefits of Investing in CFDs",
  BenefitsofInvestinginCFDsText1:
    "Wide range of trading options, allowing you to trade different financial markets without having to access multiple platforms",
  BenefitsofInvestinginCFDsText2:
    "Margin trading, employ full leverage, increase the efficiency of capital utilization",
  BenefitsofInvestinginCFDsText3:
    "Long/short trading with opportunities for profit",
  BenefitsofInvestinginCFDsText4:
    "No need to own the physical assets, flexible trading, saving various transaction levies",
  BenefitsofInvestinginCFDsText5:
    "With over 10,000 CFD products available on Lay Ray, including stocks, indices, futures and more, investors have the flexibility to trade with extremely low spreads as low as 0.1 Pips and enjoy a first-class trading experience.",
  Over10000TradingInstruments: "Over 10,000+ Trading Instruments",
  Over10000TradingInstrumentsText:
    "Seize endless trading opportunities with full access to CFDs on over 10,000 products.",
  UltraLowSpreads: "Ultra-Low Spreads",
  UltraLowSpreadsText:
    "Spreads as low as 0.1 Pips to keep transaction costs to a minimum.",
  Leverageof500: "Leverage of 500x",
  Leverageof500Text:
    "Start trading with a small amount of capital and leverage up to 500x freely to maximize your returns.",
  CFDTradingAdvantagesComparison: "CFD Trading Advantages Comparison",
  CFDTradingAdvantagesComparisonText:
    "As a highly leveraged financial derivative, CFDs are traded on margin and high leverage is their main advantage, allowing you to take more market share and gain more access to global markets with a small initial capital.",
  SecuritiesCFD: "Securities CFD",
  FuturesCFD: "Futures CFD",
  cfdstab11: "Leverage",
  cfdstab12:
    "Restrictions on securities margin, leverage is generally not more than 3 times",
  cfdstab13:
    "CFD for individual securities and CFD for individual securities are 10x and 20x respectively*",
  cfdstab14: "Ranges from 8-12 times in general",
  cfdstab15: "Up to 200 times for indices CFD and commodities CFD",
  cfdstab21: "Margin Requirements",
  cfdstab22: "High",
  cfdstab23: "Low",
  cfdstab31: "Trading Hours",
  cfdstab32:
    "5 days a week, 6.5 hours and 5.5 hours per day for U.S. and HK stocks respectively",
  cfdstab33: "Up to 6.5 hours per day, 5 days per week*",
  cfdstab34:
    "5 days per week, up to 23 hours per day for electronic trading on a single exchange",
  cfdstab35: "5 days a week, up to 23 hours a day*",
  cfdstab41: "Products Restricted by Regions and Account",
  cfdstab51: "Rollover Requirement",
  cfdstab61: "Trading Cost",
  cfdstab62:
    "Each transaction is charged a fixed rate of commission, exchange and regulatory levies, taxes and fees, and generally has a minimum commission",
  cfdstab63: "Low cost of capital, with low or zero fees *",
  cfdstab71: "Trading Platform",
  cfdstab72:
    "Buy and sell through selected trading platforms provided by brokers or banks",
  cfdstab73:
    "Choose and trade from a number of professional trading platforms such as MT4, MT5, InTrade, TradingView, FIX API 4.4, etc. *",
  AccordingtostandardofDooFinancial:
    " * According to standard of Lay Ray ",
  CFDProducts: "Lay Ray's 10,000+ Diverse CFD Products",
  CFDProductsTime:
    "Trade CFDs on 10,000 products worldwide with just one account and indulge in a robust and convenient global investment experience",
  ForexLabel: "USD/JPY, EUR/CAD, GBP/USD and other popular currency pairs",
  PreciousMetalsLabel:
    " Gold, silver and other precious metals portfolio to help you achieve asset growth",
  EnergyLabel:
    "Diversify your portfolio by accessing energy markets to trade crude oil, etc.",
  IndexCFDsLabel:
    "Offers trading on the most popular stock indices around the world",
  StockCFDsLabel:
    "Support Facebook, Alibaba and other 300+ featured U.S. and Hong Kong stocks",
  FuturesCFDsLabel:
    "Trade a wide range of commodity futures and stock index futures to benefit from market volatility",
  MultiplePlatformsCFDsContent:
    "Multiple popular software at your disposal to elevate your professional and convenient trading experience",
  ForMoreInformationAboutCFDs: "For More Information About CFDs",
  WhatisCFD: "What is CFD?",
  WhatisCFDText:
    "A contract for difference refers to a transaction method in which buyers and sellers enter into a contract and settle through the difference between the settlement price and the contract price, and does not involve direct transactions in securities.",
  WhatarethecostsofCFDs: "What are the costs of CFDs?",
  WhatarethecostsofCFDsText:
    "Lay Ray makes profit through spreads, that is, the difference between buying and selling prices. In addition, if you hold a position overnight after 5pm Eastern Time, you may be charged overnight interest.",
  Whatisovernightinterest: "What is overnight interest?",
  WhatisovernightinterestText:
    "vernight interest is the interest paid or earned by holding a position overnight.",
  Whatismargin: "What is margin?",
  WhatismarginText:
    "Margin can be considered as the actual deposit required to maintain an open position. This is not a fee or transaction cost, but simply a portion of the account equity allocated and distributed as a margin deposit.",
  AMobilecentric: "A Mobile-centric",
  tradingplatformintrade: "Pioneering and Transformative Trading Platform",
  tradingplatformintradeLabel:
    "Massive trading products, supporting tens of thousands of U.S. Stocks, Hong Kong Stocks and futures products around the world, one-click connection to global investment.",
  SatisfyTradingNeedsatYourFingertips:
    "Satisfy Trading Needs at Your Fingertips",
  SatisfyTradingNeedsatYourFingertipsText:
    "Play around with US Stocks, Hong Kong Stocks and global futures with a single account to keep your funds active",
  HighSpeedOnlineAccountRegistration: "High Speed Online Account Registration",
  HighSpeedOnlineAccountRegistrationText:
    "Simple and secured online account registration with only a single valid document that available 24/7 anytime and anywhere.",
  AbundantinInvestmentTypes: "Abundant in Investment Types",
  AbundantinInvestmentTypesText:
    "Covering U.S. and Hong Kong stocks, ETFs, mutual funds, stock options and global futures products, providing you with more profitable choices.",
  GlobalInvestmentsatYourFingertips: "Global Investments at Your Fingertips",
  GlobalInvestmentsatYourFingertipsText: "Unprecedented first-class financial trading services at Lay Ray.",
  AboutDooFinancial: "About Lay Ray",
  AboutDooFinancialText: "Lay Ray is an Internet brokerage brand, which aims to provide professional investors with trading brokerage services for financial products such as global securities, futures, and differences.",
  LeveragingText: "Leveraging on the Group's leading financial technology and financial ecology advantages, Lay Ray is able to deliver a convenient and reliable investment experience through extremely fast online account opening, efficient deposit and withdrawals.",
  Asafully: "As a fully licensed online brokerage firm, Lay Ray's operating entities hold financial regulatory licenses in the U.S. and the U.K. We are strictly regulated by regulatory entities such as the SEC and FINRA in the U.S., and the Financial Conduct Authority (FCA) in the U.K., respectively. ",
  VisionandMission: "Vision and Mission",
  Emergeasaglobal: "Emerge as a global leading online brokerage with financial technology as its core.",
  Servingglobalinvestors: "Serving global investors and transforming global financial asset investment into an effortless matter.",
  OurValues: "Our Values",
  ClientSucess: "Client Sucess",
  Weprioritizeourclients: "We prioritize our clients' interests and provide safe, reliable and convenient investment services in global financial products.",
  Trustworthy: "Trustworthy",
  Asafinancialcompany: "As a financial company, the safety of our clients' assets is our core responsibility, and we will strictly adhere to our core value. ",
  EmbraceInnovation: "Embrace Innovation",
  Wefirmlybelievethatfinancialtechnologywillbring: "We firmly believe that financial technology will bring revolutionary changes to the financial industry. We strive to continuously embrace innovation and move forward.",
  CompanyMilestone: "Company Milestone",
  Sinceitsestablishment: "Since its establishment, LayRay has provided the best trading experience to more than 21,000 professional traders under strict compliance supervision in several regions around the world.",
  Present: "Present",
  GlobalLayout: "Global Layout",
  ProductInnovation: "Product Innovation",
  SetUpBangkok: "Set Up Bangkok, Thailand office.",
  EstablishedDubaiofficeinUAE: "Established Dubai office in UAE.",
  CooperatedtopromoteClearingCloud: "Cooperated to promote Clearing Cloud's large-scale multilateral clearing system to help brokers of different sizes in connecting with liquidity providers.",
  Offeredtraderswithmore: "Offered traders with more than 10,000 trading products.",
  BrandHistory: "Brand History",
  LicenseRegulation: "License Regulation",
  AcquiredPeterElishInvestmentsSecurities: "Acquired Peter Elish Investments Securities, an American broker with a 30-year history.",
  LaunchesDooFinancial: "Launches Lay Ray, an onshore online brokerage brand.",
  PartnerwithTradingCentralText: "Partner with Trading Central to create more diverse and comprehensive professional technical analysis tools.",
  SetupDallas: "Set up Dallas, USA, and Ho Chi Minh City, Vietnam offices.",
  Thegroupobtainedlicenses: "The group obtained licenses from the United States Securities and Exchange Commission (SEC) and the Financial Industry Regulatory Authority (FINRA).",
  Clientswhoopenanaccount: "Clients who open an account in the United States can also enjoy the protection of up to USD500,000 provided by the Securities Investor Protection Corporation (SIPC), effectively securing the safety of clients' funds.",
  ObtainedalicensefromMauritius: "Obtained a license from Mauritius’ Financial Services Commission (MFSC).",
  PurchasedProfessionalIndemnityInsurance: "Purchased Professional Indemnity Insurance (PII) to protect the safety of our customers' assets as well as their rights and interests in all aspects.",
  AddedfeaturedUS: "Added 300+ featured U.S. and Hong Kong stocks and futures products.",
  SignedacontracttoenterFOLLOWME: "Signed a contract to enter FOLLOWME, the world's leading forex trading community, to cooperate to enhance clients' social trading experience.",
  JoinedforceswithMTEMedia: "Joined forces with MTE-Media, a one-stop financial education platform, to provide massive investor education resources.",
  CooperatedtobuildPriceCloud: "Cooperated to build Price Cloud, a quotation distribution system, to provide fast and stable multi-market quotation access and integration services.",
  OfferedadedicatedVirtual: "Offered a dedicated Virtual Private Server (VPS) to enable a more secure and uninterrupted trading environment for customers.",
  Institutionalclientsreached: "Institutional clients reached 400+.",
  StrategicpartnershipwithAlphaStrategy: "Strategic partnership with Alpha Strategy to provide in-depth financial analysis including daily research reports, as well as currency and stock commentaries ",
  SetupSingapore: "Set up Singapore, Mauritius, Seychelles, and Vanuatu offices.",
  Thegroupobtained: "The group obtained a license from the Financial Conduct Authority (FCA) in the United Kingdom.",
  Continuedtoexplorevarious: "Continued to explore various trading options, as well as accessing more U.S. stock and futures trading products.",
  Cooperatedwithanumberoffintechcompanies: "Cooperated with a number of fintech companies to launch InTrade, a trading terminal, and Outrade, a community trading platform.",
  ConnectedtoTradingView: "Connected to TradingView, a leading cloud and web-based trading platform, to help clients exchange massive chart indicators and trading ideas.",
  Establishedastrategicpartnership: "Established a strategic partnership with Myfxbook to strongly promote AutoTrade, a mirror trading system.",
  DooMT5trading: "LayRay MT5 trading platform is officially launched and it is more powerful and easier",
  BrokerForceversion: "BrokerForce version 4.0 upgrade unveiled, improved report statistics function.",
  LaunchedtheDooClearing: "Launched the LayRay Clearing brand to provide brokers with high-quality liquidity access and clearing services.",
  SetupLondonUKoffice: "Set up London, UK office.",
  ObtainedalicensefromVanuatu: "Obtained a license from Vanuatu Financial Services Commission (VFSC) .",
  Introducedanewgeneration: "Introduced a new generation of trading software MT5 to support more powerful features.",
  SetupSeoulSouthKoreaoffice: "Set up Seoul, South Korea office.",
  Expandedpreciousmetals: "Expanded precious metals, energy, and global mainstream index trading products.",
  DooTechlaunched: "LayRay Tech launched to provide investors with a reliable and market-tested fintech. ",
  LaunchedDooForexasaretailbrokerage: "Launched LayRay Forex as a retail brokerage brand to provide excellent retail trading services.",
  Introducedinternationally: "Introduced internationally recognized professional trading software MT4 to provide popular currency pair products.",
  CollaboratedwithFinPoints: "Collaborated with FinPoints, a Singaporean fintech company, to develop BrokerForce, an online broker customer relationship management system.",
  DooGroupwasestablishedtocarryoutits: "LayRay was established to carry out its group enterprise operation.",
  Establishedacore : "Established a core R&amp;D and technical support team.",
  FurtherGlobalGrowth: "Further Global Growth",
  Withofficesinevery: "With offices in every major financial center in the world, Lay Ray has a global presence to help you steadily reach global markets. ",
  GlobalOffices: "Global Offices",
  ActiveClientsWorldwide: "Active Clients Worldwide",
  TradingProducts: "Trading Products",
  IndustryElites: "Industry Elites",
  IntroducedtheDooExchange :"Introduced the LayRay Exchange brand to provide honest, reliable, and affordable currency exchange services.",
  Thegroupobtainedatrust: "The group obtained a trust or company service provider (TCSP) license in Hong Kong.",
  EstablishedHongKong: "Established Hong Kong, China office.",
  TopTierQualityOrientedService: "Top-Tier Quality-Oriented Service",
  PremiumCustomerServiceprovided: "Premium Customer Service provided by 500 + Professional Elites",
  ContactService: "online Service",
  Email: "Email",
  SalesRepresentative: "Sales Representative",
  emailprotected: "[email&#160;protected]",
  TechnicalSupport: "Technical Support",
  Marketing: "Marketing",
  RiskDisclosureofHigh: "Risk Disclosure of High-risk Trading Instruments",
  Tradinginfinancialinstrumentsinvolveshigh: "Trading in financial instruments involves high risks due to the fluctuation in the value and prices of the underlying financial instruments. Due to the adverse and unpredictable market movements, large losses exceeding the investor's initial investment could incur within a short period of time. The past performance of a financial instrument is not an indication of its future performance. Please make sure you read and fully understand the trading risks of the respective financial instrument before engaging in any trade with us. You should seek independent professional advice if you do not understand the risks disclosed by us herein. ",
  Youmayleaveyourcontactinformationhere: "You may leave your contact information here, and a Lay Ray representative will contact you within 1 working day.",
  FirstName: "First Name",
  LastName: "Last Name",
  PhoneNumber: "Phone Number",
  CountryRegion: "Country / Region",
  RiskDisclosureofHighriskTradingInstruments: "Risk Disclosure of High-risk Trading Instruments",
  RiskDisclosureofHighriskTradingInstrumentsText: "Trading in financial instruments involves high risks due to the fluctuation in the value and prices of the underlying financial instruments. Due to the adverse and unpredictable market movements, large losses exceeding the investor's initial investment could incur within a short period of time. The past performance of a financial instrument is not an indication of its future performance. Please make sure you read and fully understand the trading risks of the respective financial instrument before engaging in any trade with us. You should seek independent professional advice if you do not understand the risks disclosed by us herein. ",
  DooFinancialisanInternetbrokerage: "Lay Ray is an Internet brokerage brand. Lay Ray global footprint marks Dallas, London, Singapore, Hong Kong, Dubai, Kuala Lumpur and other global financial and commercial centers. We strive to provide customers with professional and convenient customer service through a global layout.",
  Dallas: "Dallas",
  EdwardsRanchRoad: "5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States",
  London: "London",
  BerkeleySuite: "Berkeley Suite 35 Berkeley Square, Mayfair, London, England, W1J 5BF",
  Singapore: "Singapore",
  MilleniaTower: "#24-01A, Millenia Tower, One Temasek Avenue, Singapore 039192.",
  HongKong: "Hong Kong",
  UnitA: "Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong",
  Dubai: "Dubai",
  UnitBuildingBC04Emirates: "Unit 705A, B05, Building BC04, RAKEZ Business Zone - FZ Ras Al Khaimah, United Arab Emirates",
  Sydney: "Sydney",
  SuiteLevelPittStreet: "Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000",
  Cyprus: "Cyprus",
  AgiasFylakseos155: "Agias Fylakseos 155, First Floor, Office 102, 3083 Limassol, Cyprus",
  KualaLumpur: "Kuala Lumpur",
  LevelJalanKerinchiKualaLumpurMalaysia: "Level 3, Tower 8, Avenue 5, The Horizon Phase 2, Bangsar South City, No. 8 Jalan Kerinchi, 59200 Kuala Lumpur, Malaysia",
  Thailand: "Thailand",
  FloorRamaRoadHuaykwangSubdistrictHuaykwangDistrictBangkok: "28th Floor, Rama 9 Road, Huaykwang Sub-district, Huaykwang District, Bangkok",
  SouthAfrica: "South Africa",
  WestStSandtonJohannesburgGPSouthAfrica: "155 West St, Sandton, Johannesburg, GP, 2031, South Africa",
  Egypt: "Egypt",
  ElHoreyaAlmazahHeliopolisCairoGovernorateEgypt: "67 El-Horeya, Almazah, Heliopolis, Cairo Governorate 4461122, Egypt",
  Seychelles: "Seychelles",
  RoomB111stFloorProvidence: "Room B11, 1st Floor, Providence Complex, Providence, Mahe, Seychelles",
  Mauritius: "Mauritius",
  TheCyberatiLoungeGroundFloor: "The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius",
  Vanuatu: "Vanuatu",
  GovantBuildingPoBox1276PortVilaVanuatu: "Govant Building, Po Box 1276, Port Vila, Vanuatu",
  CooperativeStockExchange: "Cooperative Stock Exchange",
  CooperativeStockExchangeText: "LayRay has achieved in-depth cooperation with major global stock exchanges to provide clients with 10,000+ U.S. and Hong Kong stock products with advanced market quotation services to achieve global asset allocation.",
  CooperativeFuturesExchange: "Cooperative Futures Exchange",
  CooperativeFuturesExchangeText: "LayRay cooperates with the world's major futures exchanges to provide a wealth of futures varieties such as stock indices, commodities, energy, securities, interest rates, agricultural products, as well as high-quality global futures quotes data, truly enabling one account trading global futures markets.",
  MultipleInternationalLicenses: "Multiple International Licenses , With Reputable Compliance",
  DooFinancialistheonline: "LayRay is the online brokerage, a large financial services group, and its operating entities are strictly regulated by the SEC and FINRA in the U.S., the FCA in the UK, the FSC in Mauritius and the VFSC in Vanuatu.",
  CuttingedgeTechnologywithFinancialInnovation: "Cutting-edge Technology with Financial Innovation as Core",
  Asacuttingedgeonline: "As a cutting-edge online brokerage firm, LayRay focuses on technology development and technological innovation, with a strict data security system and powerful hardware support facilities. LayRay constantly enrich its functions and enhance its execution, aiming to become the world's leading technology brokerage firm and provide customers with the ultimate trading experience.",
  GlobalExpansionthroughInternationalDevelopment: "Global Expansion through International Development",
  DooFinancialsparent: "LayRay's committed to building a global ecological network with operations centers in Dallas, London, Singapore, Hong Kong, Dubai and Kuala Lumpur, aiming to provide one-stop global investment and financial transaction services to high-net-worth clients through a global layout.",
  WhyPartnerwithUs: "Why Partner with Us",
  GroundbreakingFundingManagement : "Ground-breaking Funding Management ",
  Secureandexpeditedtradingenvironmentatyourfingertips: "Secure and expedited trading environment at your fingertips",
  Strongcapitalhighendriskmanagement: "Strong capital, high-end risk management",
  DooGroupisalarge: "LayRay is a large integrated service group with financial technology as its core. By the virtue of strong capital strength, cutting-edge risk management technology and the strict supervision of multiple global financial regulatory agencies, LayRay is able to achieve all-rounded protection of clients' investment security. ",
  USD5million: "USD5 million +",
  RegulatoryCapitalRequirement: "Regulatory Capital Requirement",
  AccountRegistered: "Account Registered",
  USD15billion: "USD15 billion+",
  AverageMonthlyTradingVolume: "Average Monthly Trading Volume",
  ConstructiveCooperationwithDistinguishedBanks: "Constructive Cooperation with Distinguished Banks",
  Tofairlydistributeandsecureourclients: "To fairly distribute and secure our clients' funds, LayRay established professional partnerships with multiple leading investment-grade banks across the world.",
  SegregationandClientFunds: "Segregation and Client Funds",
  DooFinancialsegregatesclient:"LayRay segregates client funds from the company's operating funds with: Bison Bank, BARCLAYS, Standard Chartered.",
  Weadheretotherigoroussystemofinternationalbanks: "We adhere to the rigorous system of international banks to ensure client funds are dedicated and protected at all times.",
  ProtectingClientsAssestInsurance: "Protecting Client's Assest with Professional Insurance",
  Accountsmaintainedwith: "Accounts maintained with LayRay's U.S. financial entity enjoy protection by the US Securities Investor Protection Corporation (SIPC) of up to 500,000 U.S. dollars ($250,000 limit on cash).*",
  ConvenientandDiverseDepositandWithdrawalMethods: "Convenient and Diverse Deposit and Withdrawal Methods ",
  AtDooFinancialdepositandwithdrawyour: "At LayRay, deposit and withdraw your funds hassle-free with a variety of secure and easily accessible funding methods. ",
  Supportedcurrencies: "Supported currencies: AED、AUD、BRL、COP、CLP、CNY、EUR、EUSD、GBP、HKD、KRW、KHR、LAK、MMK、MXN、NGN、PEN、THB、USD、USDT、VND、ZAR",
  Deposit: "Deposit",
  LocalBankTransfer: "Local Bank Transfer",
  PaymentChannel: "Payment Channel",
  PaymentCurrency: "Payment Currency",
  LimitUSD: "Limit (USD)",
  Fees: "Fees",
  ProcessingTime: "Processing Time",
  Nocharges: "No charges",
  Instant: "Instant",
  minutes: "minutes",
  Charges: "Charges",
  workingdays25: "2-5 working days",
  LocalBankTransferText1: "USD 0-50,000 , 1 working day",
  LocalBankTransferText2: "USD 50,000-200,000 , 2 working days",
  LocalBankTransferText3: "USD 200,000-1,000,00 , 5 working days",
  Notesondepositmethod: "Notes on deposit method: ",
  NotesondepositmethodText1: "1. The recommended minimum deposit amount is USD 100. ",
  NotesondepositmethodText2: "2. Other foreign currencies will be converted according to the USD exchange rate. ",
  NotesondepositmethodText3: "3. All deposits must be paid by the person's account, third-party payment will be refunded according to the payer's information. ",
  NotesondepositmethodText4: "4. Please do not fill in any remarks for the transfers. If there are remarks/notes or sensitive information, it will be rejected and returned back to the payer. ",
  NotesondepositmethodText5: "5. If the client's transferred amount does not match the order payment amount, the order cannot be adjusted. We will not process and deposit the funds received. If the client paid more, the payment service provider will arrange a refund.If the client paid less, the payment service provider will request to release the order or arrange a refund after the payment is processed.",
  WithdrawalMethods: "Withdrawal Methods",
  InternationalWireTransfer: "International Wire Transfer",
  InternationalWireTransferText1: "USD 0-50,000 , 1 working day",
  InternationalWireTransferText2: "USD 50,000-200,000 , 2 working days",
  InternationalWireTransferText3: "USD 200,000-1,000,00 , 5 working days ",
  InternationalWireTransferText4: "USD 1,000,00 &amp; above , 6 working days ",
  Pleaseberemindedthatwhenwithdrawing: "Please be reminded that when withdrawing: ",
  PleaseberemindedthatwhenwithdrawingText1: "1. The recommended minimum withdrawal amount is USD 50. ",
  Ewallet: "E-wallet",
  Withdrawal: "Withdrawal",
  ActiveAccount: "Active Account",
  VerifiedAccount: "Verified Account",
  Theindustryslowest: "The industry&#8217;s lowest and transparent commissions to help traders reduce costs and maximize profits.",
  USSecuritiesTrading: "U.S. Securities Trading",
  LowCostFlexibleandTransparent: "Low Cost, Flexible and Transparent",
  PerShare: "Per Share",
  PerTransactionMinimum: "Per Transaction Minimum",
  AgentFee: "Agent Fee",
  RegulatoryFees: "Regulatory Fees",
  Tradingactivityfeesetc: "Trading activity fees, etc.",
  FindOutMore: "Find Out More",
  HongKongSecuritiesTrading: "Hong Kong Securities Trading",
  TopintheIndustrywithUltraLowCommission: "Top in the Industry with Ultra-Low Commission",
  Aslowas: "As low as",
  Minimum: "Minimum",
  PlatformFee: "Platform Fee",
  pershare: "per share",
  StampDuty: "Stamp Duty",
  FuturesTrading: "Futures Trading",
  BetterPriceGreaterSavings: "Better Price, Greater Savings",
  Percontractforperside: "Per contract for per side",
  Exchangefees: "Exchange fees",
  Regulatoryfeesetc: "Regulatory fees, etc.",
  CFDsTrading: "CFDs Trading",
  UltraLowSpreadswithMaximumSecurity: "Ultra-Low Spreads with Maximum Security",
  SpreadsorCommission: "Spreads or Commission",
  SpreadsorCommissionText: "The spread is the difference between the bid price and the ask price and is the main transaction cost for CFD trading. As the spread fee is not fixed, you may login to the trading platform to check it, and as for stock CFD trading, you pay a commission.",
  OvernightInterest: "Overnight Interest",
  OvernightInterestText: "If you hold a position in a Spot CFD after the daily settlement time, you will be charged an overnight fee, which can be viewed by logging in to Trade Balance for each product.",
  OtherFees: "Other Fees",
  OtherFeesText: "There will be no extra fees. Our fee policy is completely transparent, and any items that require a fee will be announced and communicated in advance, so there is no need to worry about hidden fees.",




};
